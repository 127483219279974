import { Alert, AlertTitle, Card, Grid, Link, Typography } from '@mui/material'
import React from 'react'

const HomePage = ({ drawerWidth, environment }) => {
    return (
        <Grid container spacing={2} style={{ width: 'auto', marginTop: "30px", marginLeft: drawerWidth }} >
            <Grid container direction="row" rowSpacing={2} columnSpacing={2}>
                <Grid item xs={12} md={8}>
                    <Card variant="filled" sx={{ width: 'auto', padding: '50px', backgroundColor: 'primary.light' }}>
                        <Typography variant="h3">Welcome to the Investment Data Hub.</Typography>
                        <Grid item xs={12}>
                            <Typography variant="span">
                                <p>
                                    The purpose of the M&G Data Hub is to provide people across M&G with the opportunity to explore the data that is available within the Investment Data Platform (IDP).
                                    Data is stored within the IDP as "Data Products" and a catalogue of Products can be found here.
                                </p>
                                <p>
                                    Data can be downloaded directly from the Hub into a csv file. If you can't find the data product that you're looking for please contact the <Link href="mailto:Investment.Data.Platform.User.Data.Access.Enablement@mandg.com" color="primary">Investment Data Transformation Services team</Link> who will be able to assist.
                                </p>
                                <p>
                                    If you want to use data from the Hub as part of a critical business process, then please contact the <Link href="mailto:Investment.Data.Platform.User.Data.Access.Enablement@mandg.com" color="primary">Investment Data Transformation Services team</Link> who can discuss your requirements and assess if using the Hub is the most appropriate solution. Alternative methods of accessing the data from the IDP may be more suitable.
                                </p>
                            </Typography>
                        </Grid>
                    </Card>
                </Grid>
                <Grid item xs={12} md={4} lg={3.5}>
                    <Alert severity="warning" color="secondary" sx={{ marginBottom: '10px' }}>
                        <AlertTitle>Technical Issues</AlertTitle>
                        <Typography variant="span">If you experience any technical issues with the Hub please contact the <Link href="mailto:Investment.Data.Platform.User.Data.Access.Enablement@mandg.com" color="primary">Investment Data Platform Support</Link>.</Typography>
                    </Alert>
                    <Alert severity="info" color="secondary">
                        <AlertTitle>Access Information</AlertTitle>
                        <Typography variant="span">
                            <p>
                                The ARC role required for general access to the Hub is: <br></br><b>Corp - InvestmentDataHub - { environment }</b>
                            </p>
                            <p>
                                The Admin access role is: <br></br><b>Corp - Azure App { environment === 'Prod' ? '' : environment } - M&G DataHub - Admin Investments</b>
                            </p>
                            <p>
                                For access queries please contact the <Link href="mailto:Investment.Data.Platform.User.Data.Access.Enablement@mandg.com" color="primary">Investment Data Transformation Services team</Link>.
                            </p>
                        </Typography>
                    </Alert>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default HomePage